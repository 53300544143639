.bg_color {
}
.homeimage{
    background-image: url(../../assets/CakeHut_Hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.custom_btn {
    border-radius: 17px !important;
    background-color: white !important;
    border-color: white !important;
    color: black !important;
}

.menu {
    padding-right: 24px !important;
    padding-left: 25px !important;
}

.custom_padding_bg {
    padding-bottom: 50px !important;
}

.bg_cakes {
    background: linear-gradient( rgba(85,39,39,1) 33%, rgba(213,213,213,1) 66%, rgba(85,39,39,1) 99%)!important; 
}

.maintext{
    margin-top: 120px;
    color: white;
    text-align: center;
    font-family:Helvetica;
    font-size: 36px;
    line-height: 54px;
    font-weight:700;
    text-align:center;
 

}
.premises{
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: normal;
    font-family: sans-serif;
    font-style: italic;
}
.btnmain{
  display: flex;
  justify-content: center;  
    width: 100%;
    gap: 3rem;

}
.btntelp{
    background-color: white;
    color: #552727;
    font-weight: bold;
    border: #552727 solid 2px;
    border-radius: 50px ;
    height: 40px;
    width: 200px;
}
.btnmenu
{
    background-color: white;
    color: #552727;
    font-weight: bold;
    border: #552727 solid 2px;
    border-radius: 50px ;
    height: 40px;
    width: 200px;
}


@media only screen and (max-width: 450px){
    .btnmain{
        display: flex;
        flex-direction: column;
          width: 50%;
          gap: 1rem;
      
      }   
      .premises{
        text-align: center;
        color: white;
        font-size: 23px;
        font-weight: normal;
        font-family: sans-serif;
        font-style: italic;
    }
    .maintext{
        margin-top: 200px;
        font-size: 28px;
    line-height: 54px;
    font-weight:700; 
    }
    .btntelp{
        margin-top: -30px;
    }
    
    
}