.customs_logo {
    max-width: 10% !important;
    height: auto !important;
}
.button{
    margin-top: 20px;
    border-radius: 50px;
    padding: 10px;
    border: none;
    background-color:#efefef;
    height: 80px;
    width: 80px;
}
.L{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 4px;
    
}

.text33{
    color: #552727;
}

.buttons{
    
    border-radius: 50px;
    padding: 2px;
    border: none;
    text-decoration: underline;
    background-color:#efefef;
}
.text{
    font-weight: bold;
   font-size: large;
   margin-top: 5px;
   background-color: pink;
   

}

