.custom_logo {
    max-width: 40% !important;
    height: auto !important;
}
.textfooter{
    color: #552727;
}
.footericon{
    height: 25px;
    width: 25px;
    color: #552727;
}
.textfooter2{
    color: #552727;
    font-size: 20px;
    font-weight: bold;

}

.textfooter3{
    color: #552727;
    font-size: 12px;

}

@media only screen and (max-width: 800px){
    .textfooter2{
        color: #552727;
        font-size: 14px;
    
    }
    
    .textfooter3{
        color: #552727;
        font-size: 12px;
    
    }
}