.menuimage{
    /* height: 100vh; */
   background-image: url(../../assets/CakeHut_Hero.jpg);
   height: 65vh;
   background-repeat: no-repeat;
   background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-menu{
    background-color: #e6e6e6;
}
.mainmenu{
    color: white;
    font-size: 100px;
    font-weight: bold;
    margin: auto;
}

.row-spider{
    display: flex;
    justify-content: center;
    gap: 15rem;
}
.spider{
    display: flex;
    justify-content: center;
    gap: 5rem;
    
}
.imgspider{
    height: 300px;
   width: 300px;
}
.edible{
    gap: 5rem;
    
}
.edibletext{
    text-align: center;
}
.hifrozon{
font-size: 22px;
}

.div-forzon{
    display: flex;
    gap: 3rem;
    justify-content: center;
}
.frozen-img{
    height: 80px;
    width: 80px;
}

.div-custom{
    display: flex;
    gap: 3rem;
    justify-content: center;
}

.custom-img{
    height: 120px;
    width: 120px;

}

.hicustom{
    font-size: 22px;
    text-align: center;
}
.pcustom{
    font-size: 15px;
text-align: center;
}

.main-tortesimage{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.one{
    display: flex;
    justify-content: center;
}
.two{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
@media only screen and (max-width: 1250px){
    .row-spider{
        display: flex;
        justify-content: center;
        gap: 10rem;
    }
}

@media only screen and (max-width: 1050px){
    .row-spider{
        display: flex;
        justify-content: center;
        gap: 4rem;
    }
}

@media only screen and (max-width: 700px){
    .row-spider{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
    .imgspider{
        height: 250px;
       width: 250px;
    }

    .frozen-img{
        height: 60px;
        width: 60px;
    }
    .hifrozon{
        font-size: 15px;
        }

        .spider{
            width: 100%;
        }

        .edible{
            width: 100%;
        }
        
.custom-img{
    height: 120px;
    width: 120px;

}

.hicustom{
    font-size: 15px;
    text-align: center;
}
.pcustom{
    font-size: 10px;
text-align: center;
}

}



@media only screen and (max-width: 450px){
    
    .imgspider{
        height: 285px;
        width: 289px;
    }

    .frozen-img{
        margin-top: 8px;
        height: 40px;
        width: 40px;
    }
    

    .div-custom{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3rem;
        justify-content: center;
    }

    .hifrozon{
        font-size: 12px;
        }

        .div-custom{
            display: flex;
            flex-direction: inherit;
            gap: 3rem;
            justify-content: center;
        }
        

}

    



