.bg_cakes {
    background-color: #55272700;
}

.card_margin {
    margin-left: 15px !important;
}

.custom_gallery_padding {
    padding: 30px !important;
}

.balan {
    width: 100%;
   
}


.top-maincontainer {
    display: flex;
    justify-content: center;
    align-items: center;

}

.container-hometop {
    width: 90%;
}

.row-hometop {
    display: flex;
    justify-content: center;
    gap: 3rem;

}

.maintopimg {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hometop {
    width: 200px;
}

.topimage {
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.spdider-man
{
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.texth5 {
    background-color: white;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #552727;
    font-family: Helvetica;
    font-size: 26px;
    font-weight: 400;
    font-style: italic;
}


.row-homedown {
    
    display: flex;
    justify-content: center;
    gap: 3rem;
    
}

.row-homedown2 {
    display: flex;
    justify-content: center;

}

.imgcake1 {
    height: 100px;
    margin-top: 10px;
}

.topimage2 {
    width: 95%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;

}


@media only screen and (max-width: 800px) {

    .row-homedown {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        
    }

    .row-hometop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;

    }

    .hometop {
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .texth5 {
        background-color: white;
        height: 70px;
        width: 300px;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
        color: #552727;
        font-family: Helvetica;
        font-size: 26px;
        font-weight: 400;
        font-style: italic;
    }

    .topimage {
        width: 300px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .spdider-man
{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}


    .topimage2 {
        width: 300px;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .row-homedown2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;

    }
    .main-navbar .navbar
    {
        background: white;
    }

}