.main-navbar{
    position: fixed;
    z-index: 10;
    height: 80px;
    width: 100%;
    background-color: #552727;
    color: white;

}
.main-header1{
    position: fixed;
    z-index: 10;
    height: auto;
    width: 100%;
    background-color: #552727;
    color: white;

}

.navlogo{
    height: 100px;
    width: 130px;
}

.navtext{
    color: white;
    font-size: 20px;
    height: 50px;

}
.navtext:hover{
    color: white;
    font-size: 20px;
    font-weight: normal;
    border-bottom: 2px solid ;
    height: 50px;
}

.navbtn{
    text-transform: none;
    color: #552727;
    font-weight: bold;
    border: none;
    border-radius: 30px;
    height: 40px;
    width: 200px;
}
.navicon{
  
    color: rgb(255, 17, 104);
    height: 20px;
    width: 20px;
    
}
.naviconf{
  height: 20px;
    width: 20px;
    color: rgb(21, 105, 231);
}
.naviconm{
   
    margin-top: 2px;
    height: 20px;
    width: 20px;
    color: rgb(120, 26, 213);
}


.iconss{
    border: 2px solid white;
    border-radius: 100px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.iconss:hover{
    border: 2px solid #f6c1c0;
    border-radius: 100px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6c1c0;
}

.navbtnss{
    gap: 2rem;
}

.nav-icon{
    display: flex;
    gap: 1rem;
}
.togglebutton1{
    visibility: hidden;
}  
.togglebutton{
    visibility: hidden;
}   
.tel{
    text-decoration: none;
    color: #552727;
}
.phone-icon
{
    height: 40px;
    width: 40px;
    margin-top: -110px;
    visibility: hidden;
}

@media only screen and (max-width: 1000px){
    .togglebutton{
        visibility: visible;
        position: fixed;
        z-index: 20;
        margin-top: 50px;
        margin-left: 20px;
    }
   
    .sidetext{
        color: #552727;
        font-size: 20px;
        height: 50px;
    }
    .sidemain{
        width:260px;
       background-color: #ffffff;
    }
    .sidebtn{
        background-color: white;
        border: none;
        color: #552727;
        outline: none;
        height: 50px;
        width: 50px;
        font-size: 30px;
    }
    .tel{
        text-decoration: none;
        color: #552727;
    }
    .sidebtn:hover{
        background-color: #552727;
        border: none;
        color: white;
        outline: none;
        height: 50px;
        width: 50px;
        font-size: 30px;
    }
  

.nav-container{
    display: flex;
    justify-content: center;
    justify-items: center;
}

    .brandnav{
        width: 100%;
        height: 10  0px;
        display: flex;
        justify-content: center;
         
    }
    .navlogo{
        height: 100px;
        width: 130px;
    }

    .nav-icon {
        display: flex;
        gap: 1rem;
        width: 171px;
    }
    .main-navbar{
        position: fixed;
        z-index: 10;
        height: auto;
        width: 100%;
        background-color: #ffffff;
        color: #552727;
    
    }
    .phone-icon
{
    height: 28px;
    width: 50px;
    margin-top: -120px;
    visibility: visible;
    color: #552727;
}
}


