.first-sec {

    background-image: url('../../assets/CakeHut_Hero.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;

}


.about-text {
    color: white;
    font-weight: 700;
    font-size: 48px;
    font-family: Helvetica;

}

.second-sec {
    background-image: url('https://lirp.cdn-website.com/md/dmtmpl/1730c34d-0458-4f72-90f5-e35bbfedc699/dms3rep/multi/opt/Fotolia_84707605_X-1121x745-2880w.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.first-text {
    color: #552727;
    font-family: Helvetica;
    font-weight: 700;
    margin-top: 50px;
}

.weoffer{
    font-weight: bold;
    font-size: larger;
    color: #552727;

}

.second-text {
    color: #552727;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;
    
}

.third-text {
    color: #552727;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;

}

.third-sec-li {
    color: #552727;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;

}

.third-sec-li-last {
    color: #552727;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;
    font-style: italic;
}

.fourth-sec {
    text-align: center;
    color: #552727;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;
    font-style: italic;
    margin-top: 30px;

}

.five-text {
    text-align: center;
    color: #552727;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;

}

.five-text-li {
    color: #552727;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;
}

.five-text-li-2 {
    color: #552727;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;
}

.five-text-li-3 {
    color: #552727;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;
}

.five-text-li-4 {
    color: #552727;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;
}

.five-text-li-5 {
    color: #552727;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;
}

.five-text-li-6 {
    color: #552727;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;
}
.six-text
{
    margin-top: 100px;
    background-color: #552727;
    color: white;
    font-style:italic;
    font-family: Helvetica;
    font-size: 22px;
    font-weight: 400;
    border-radius: 20px;
    height: 80px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}