.first-sec-contact
{
    background-image: url(../../assets/50.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: start;
    width: 100%;
    height: 400px;
    display: flex;
    text-align: center;
    flex-direction: column;
}
.contact-text
{
    color: white;
    font-family: Helvetica;
    font-size:48px;
    font-weight:700;
    margin-top: 80PX;

  
}
.contact-text-2
{
    color: #ffffff;
    font-family: Helvetica;
    font-size:18px;
    font-weight:400;
    font-style:italic;
    margin-top: 20PX;
}
.get-h
{
    text-align: center;
    color: #552727;
    font-family: Helvetica;
    font-size:30px;
    font-weight:400;
}
.get-pra
{
    text-align: center;
    color: #552727;
    font-family: Helvetica;
    font-size:20px;
    font-weight:400;
    font-style:italic;
}
.input-data
{
    display: flex;
    flex-direction: column;
}
.input_1
{
    height: 40px;
    width: 80%;
    border: solid 2px #552727
}
.input_last
{
    height: 100px;
    width: 80%;
    border: solid 2px black
}
.but_1
{
    width: 200px;
    height: 45px;
    border: solid 2px #552727;
    border-radius: 30px;
    margin-left: 90px;

}
.contact-info
{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
    flex-direction: column;
}
.phon_mail
{
    color:#552727;
    font-family: Helvetica;
    font-size:18px;
    font-weight:400;

}
.address
{
    color:#552727;
    font-family: Helvetica;
    font-size:18px;
    font-weight:400;
    margin-top: 20px;
}
.time_det
{
    color:#552727;
    font-family: Helvetica;
    font-size:18px;
    font-weight:400;
    margin-top: 20px;
}
.map
{
    margin-left: 70px;
}

@media only screen and (max-width: 600px) {
    .but_1
{
    width: 200px;
    height: 45px;
    border: solid 2px #552727;
    border-radius: 30px;
    margin-left: 10px;
}
.map
{
    margin-left: 20px;
}

}

@media only screen and (max-width: 450px) {
    .input-data
    {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}